import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {
    //const logoColor = useColorModeValue('', "invert(100%)");

    return (
        <Box p={4} borderTopWidth={2} borderStyle={"solid"} borderColor={useColorModeValue("gray.200", "gray.700")}>
            <Center gap={1}>
                <Text fontWeight={"medium"}>Powered by</Text>
                <Text fontWeight={"bolder"}>
                    <a href="https://981block.com" target="_blank" rel="noreferrer">
                        981Block
                    </a>
                </Text>
            </Center>
            <Text fontWeight={"bolder"} textAlign={"center"}>911 17 62 00</Text>
        </Box>
    );
}
