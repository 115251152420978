import { useEffect, useState } from "react";
import { Box, Center, Image, useColorModeValue } from "@chakra-ui/react";

import { getCurrencies, getInvoice, getPing } from "../../services/apiRequest";

import Error from "./Error";
import SelectPropina from "../SelectPropina/SelectPropina";
import SelectCurrency from "../SelectCurrency/SelectCurrency";

import { getComision } from "../../services/apiRequest";
import Payment from "../Payment/Payment";
import Footer from "../../components/Footer/Footer";
import Waiting from "../Payment/Waiting";

const Home = () => {
    // Get the query parameters from the URL
    const queryParameters = new URLSearchParams(window.location.search);
    const invoiceTitle = queryParameters.get("title") ? queryParameters.get("title") : "ERROR";
    const invoicePrice = queryParameters.get("price") ? queryParameters.get("price") : "ERROR";
    const invoiceId = queryParameters.get("invoice_id") ? queryParameters.get("invoice_id") : "ERROR";

    const haveError = invoiceTitle === "ERROR" || invoicePrice === "ERROR" || invoiceId === "ERROR";

    const [needLoad, setNeedLoad] = useState(true);
    const [systemError, setSystemError] = useState(false);
    const [systemLoading, setSystemLoading] = useState(true);

    const [invoiceData, setInvoiceData] = useState(null);

    // Step 1: Select the tip
    const [comision, setComision] = useState(0.0);
    const [tip, setTip] = useState(0.0);
    const [step, setStep] = useState(0);
    const amountError =
        parseFloat(invoicePrice) + parseFloat(tip) < 10 || parseFloat(invoicePrice) + parseFloat(tip) > 999;

    // Step 2: Select the currency, network and email
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const email = "soporte@981block.com";

    const [currencies, setCurrencies] = useState([]);

    const nextStep = () => {
        if (step < steps.length - 1) {
            setStep(step + 1);
        }
    };
    const prevStep = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    useEffect(() => {
        const fetchInvoice = async () => {
            try {
                const { response } = await getInvoice(invoiceId);
                const { body } = response;
                setInvoiceData(body.original_data);

                if (
                    body.status === "pending" ||
                    body.status === "expired" ||
                    body.status === "confirming" ||
                    body.status === "paid"
                ) {
                    setStep(2);
                }
            } catch (error) {
                console.error("🚀 ~ file: Home.js:58 ~ fetchComision ~ error:", error);
            }
        };
        fetchInvoice();
    }, [invoiceId]);

    useEffect(() => {
        const checkSystem = async () => {
            try {
                const ping = await getPing();
                setSystemError(ping.error.result);
            } catch (error) {
                console.error("🚀 ~ file: Home.js:58 ~ fetchComision ~ error:", error);
                setSystemError(true);
            } finally {
                setSystemLoading(false);
            }
        };
        checkSystem();
    }, []);

    useEffect(() => {
        const fetchComision = async () => {
            try {
                setNeedLoad(false);
                const [data, currencies] = await Promise.all([getComision(), getCurrencies()]);
                setComision(data * 100);
                setCurrencies(currencies);
            } catch (error) {
                console.error("🚀 ~ file: Home.js:58 ~ fetchComision ~ error:", error);
            }
        };

        needLoad && !systemError && fetchComision();
    }, [needLoad, systemError]);

    const steps = [
        <SelectPropina
            invoiceTitle={invoiceTitle}
            invoicePrice={invoicePrice}
            tip={tip}
            setTip={setTip}
            nextStep={nextStep}
            comision={comision}
        />,
        <SelectCurrency
            invoiceTitle={invoiceTitle}
            prevStep={prevStep}
            nextStep={nextStep}
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            selectedNetwork={selectedNetwork}
            setSelectedNetwork={setSelectedNetwork}
            email={email}
        />,
        <Payment
            previousData={invoiceData}
            invoiceTitle={invoiceTitle}
            invoicePrice={invoicePrice}
            tip={tip}
            selectedCurrency={selectedCurrency}
            selectedNetwork={selectedNetwork}
            email={email}
            invoiceId={invoiceId}
            comision={comision}
        />,
    ];

    // Colores para el modo claro y oscuro
    const logoNassau = useColorModeValue("/images/Logo_NASSAU.png", "/images/Logo_NASSAU_dark.png");

    return (
        <Box mt={-4}>
            <Center>
                <Image src={logoNassau} alt="Nassau" w="175px" />
            </Center>

            {systemError && <Error message="Sistema no disponible (System not available)" />}
            {systemLoading && (
                <Box p={4}>
                    <Waiting title={"Espera, por favor"} message="Wait, please" />{" "}
                </Box>
            )}

            {!systemError && !systemLoading && (
                <Box>
                    {haveError && <Error />}
                    {!haveError && (amountError ? <Error message="Mínimo 10 € y máximo 999 € (incluida propina)" /> : steps[step])}
                </Box>
            )}

            {step !== 2 && <Footer />}
        </Box>
    );
};

export default Home;
