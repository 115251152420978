import { Box, Center, Text, useColorModeValue } from "@chakra-ui/react";
import { createInvoice } from "../../services/apiRequest";
import { useEffect, useState } from "react";
import Waiting from "./Waiting";
import ProcessPayment from "./ProcessPayment";
import Encabezado from "../../components/Encabezado/Encabezado";

const Payment = ({ invoiceTitle, invoicePrice, tip = 0, selectedCurrency, selectedNetwork, email, invoiceId, previousData, comision }) => {
    // Colores para el modo claro y oscuro
    const totalBgColor = useColorModeValue("#F5F5F5", "#282828");

    const [invoiceCreated, setInvoiceCreated] = useState(false);
    const [invoiceData, setInvoiceData] = useState(previousData);

    const generateInvoice = async () => {
        if (invoiceCreated) return;
        // Create the payment object
        const payment = {
            title: invoiceTitle,
            amount: Number(invoicePrice),
            tip: Number(tip),
            invoiceId: invoiceId,
            email: email,
            currency: selectedCurrency.currencyId,
            network: selectedNetwork.platformId,
        };

        // Send the payment object to the server
        try {
            const query = await createInvoice(payment);
            if (!query) return;
            const { response } = query;
            setInvoiceCreated(true);
            setInvoiceData(response.body);
        } catch (error) {
            console.error("🚀 ~ file: Payment.js:34 ~ generateInvoice ~ error:", error);
            // Esperar 5 segundos y volver a intentar
            setTimeout(() => {
                generateInvoice();
            }, 5000);
        }
    };

    useEffect(() => {
        if (!previousData) {
            generateInvoice();
        } else {
            setInvoiceCreated(true);
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box px={{ base: 4, md: 6 }} py={2}>
            <Encabezado msg={invoiceTitle} />

            {!invoiceCreated && <Waiting title={"Espera, por favor"} message="Wait, please" />}
            {invoiceCreated && (
                <ProcessPayment invoiceData={invoiceData} comision={comision} totalWithTip={Number(invoicePrice) + Number(tip)} />
            )}

            <Box border="1px" bgColor={totalBgColor} roundedBottom="3xl" shadow="xl">
                <Center gap={1}>
                    <Text fontWeight={"medium"}>Powered by</Text>
                    <Text fontWeight={"bolder"}>
                        <a href="https://981block.com" target="_blank" rel="noreferrer">
                            981Block
                        </a>
                    </Text>
                </Center>
                <Text fontWeight={"bolder"} textAlign={"center"}>
                    911 17 62 00
                </Text>
            </Box>
        </Box>
    );
};

export default Payment;
