// import { useEffect, useState } from "react";
import { Box, Button, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";

import TipSelector from "../../components/TipSelector/TipSelector";
import Encabezado from "../../components/Encabezado/Encabezado";

const SelectPropina = ({ invoiceTitle, invoicePrice, tip, setTip, comision, nextStep }) => {
    // Variables
    // const [totalPrice, setTotalPrice] = useState(invoicePrice);

    
    const totalBgColor = useColorModeValue("#F5F5F5", "#282828");

    // Calculate the tip with a percentaje
    const handleChangeTip = (event) => {
        const tipPercentaje = event.target.value / 100;
        const tip = Number(invoicePrice * tipPercentaje).toFixed(2);
        setTip(tip);
    };

    // Calculate the tip with a custom value
    const handleChangeValueTip = (event) => {
        setTip(Number(event.target.value).toFixed(2));
    };

    // // Calcular el total de la factura
    // useEffect(() => {
    //     // Calculate the total price
    //     const calculateTotalPrice = () => {
    //         const total = Number(Number(invoicePrice) + Number(tip)).toFixed(2) * (1 + Number(comision) / 100);
    //         setTotalPrice(total.toFixed(2));
    //     };

    //     calculateTotalPrice();
    // }, [tip, invoicePrice, comision]);

    const priceWithTip = Number(invoicePrice) + Number(tip);

    return (
        <Box px={{ base: 6, md: 8 }} py={2}>
            <Encabezado msg={invoiceTitle} />

            <Box border="1px" px={6} py={4} mt={-0.5} shadow="2xl">
                <TipSelector handleChangeTip={handleChangeTip} handleChangeValueTip={handleChangeValueTip} />
            </Box>

            <Box border="1px" bgColor={totalBgColor} roundedBottom="3xl" shadow="xl">
                <SimpleGrid columns={2} px={6} pt={2}>
                    <Text textAlign="left">
                        Factura <strong>(Bill)</strong>
                    </Text>

                    <Text textAlign="right" fontWeight="bolder">
                        {invoicePrice} {invoicePrice !== "ERROR" && "€"}
                    </Text>
                </SimpleGrid>

                <SimpleGrid columns={2} px={6}>
                    <Text textAlign="left">
                        Propina <strong>(Tip)</strong>
                    </Text>

                    <Text textAlign="right" fontWeight="bolder">
                        {invoicePrice !== "ERROR" ? tip + " €" : "ERROR"}
                    </Text>
                </SimpleGrid>

                <SimpleGrid columns={2} px={6}>
                    <Text textAlign="left" fontWeight="bolder" fontSize="xl">
                        TOTAL
                    </Text>

                    <Text textAlign="right" fontSize="xl" fontWeight="bolder">
                        {invoicePrice !== "ERROR" ? priceWithTip + " €" : "ERROR"}
                    </Text>
                </SimpleGrid>
                <SimpleGrid my={4} mx={6}>
                    <Button p={8} fontSize="md" fontWeight="bold" rounded="full" bgColor="#00AEE1" color="white" onClick={() => nextStep()}>
                        SIGUIENTE / NEXT
                    </Button>
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default SelectPropina;
