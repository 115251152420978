import {
    Box,
    Center,
    Heading,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Spacer,
    Stack,
    Text,
    useClipboard,
    useToast,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { getInvoice } from "../../services/apiRequest";

import { FaCopy } from "react-icons/fa";
import Error from "../Home/Error";

const ProcessPayment = ({ invoiceData, comision, totalWithTip }) => {
    const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard(invoiceData.paymentAddress);
    const { hasCopied: hasCopiedAmount, onCopy: onCopyAmount } = useClipboard(invoiceData.payAmount);
    const toast = useToast();

    const [needCheck, setNeedCheck] = useState(false);
    const [paymentDetected, setPaymentDetected] = useState(invoiceData.status === "paid" || invoiceData.status === "confirming");

    // Counter 20 minutes
    const created = new Date(invoiceData.createdAt);
    const [counter, setCounter] = useState(Math.floor((created.getTime() + 1200000 - Date.now()) / 1000));

    // Esperar 30 segundos para verificar el pago, solo 1 vez
    let fistTime = true;

    setTimeout(() => {
        if (fistTime) {
            setNeedCheck(true);
            fistTime = false;
        }
    }, 30000);

    useEffect(() => {
        if (hasCopiedAddress) {
            toast({
                title: "You got it!",
                description: "Dirección copiada (Address copied)",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
    }, [hasCopiedAddress, toast]);

    useEffect(() => {
        if (hasCopiedAmount) {
            toast({
                title: "You got it!",
                description: "Cantidad copiada (Amount copied)",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
    }, [hasCopiedAmount, toast]);

    const checkPay = useCallback(async () => {
        const response = await getInvoice(invoiceData.foreignId);
        return response.response.body;
    }, [invoiceData.foreignId]);

    useEffect(() => {
        let interval;
        const startInterval = async () => {
            interval = setInterval(async () => {
                const payment = await checkPay();
                if (payment.status === "paid" || payment.status === "confirming") {
                    setPaymentDetected(true);
                    setNeedCheck(false);
                    clearInterval(interval);
                }
            }, 7500);
        };

        if (needCheck && !paymentDetected && counter !== 0) {
            startInterval();
        }

        return () => clearInterval(interval);
    }, [checkPay, needCheck, paymentDetected, counter]);

    useEffect(() => {
        const timer =
            counter > 0 &&
            setInterval(() => {
                setCounter(counter - 1);
            }, 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const totalComision = (invoiceData.payAmount * comision) / 100;

    return (
        <Stack border="1px" px={6} py={4} mt={-0.5} shadow="2xl">
            {counter > 0 && !paymentDetected && (
                <>
                    <Stack direction="row" border="1px" rounded="md" p={1} spacing={4}>
                        <Text fontSize="xs">
                            Tiempo restante <br />
                            (Time remaining)
                        </Text>
                        <Spacer />
                        <Center gap={2} fontWeight={"bold"}>
                            <Text fontSize="xs" textAlign="center">
                                {Math.floor(counter / 60)} m
                            </Text>
                            <Text fontSize="xs" textAlign="center">
                                {counter % 60 < 10 ? "0" + (counter % 60) : counter % 60} s
                            </Text>
                        </Center>
                    </Stack>

                    <Text fontSize="xs" textAlign="center">
                        Si yas has pagado, espera unos segundos
                        <br /> (If you have already paid, wait a few seconds)
                    </Text>

                    {invoiceData.paymentRequestUri && (
                        <Center>
                            <Box p={1}>
                                <a href={invoiceData.paymentRequestUri} target="_blank" rel="noreferrer">
                                    <Box bgColor={"white"} rounded={"xl"} p={2} shadow={"md"}>
                                        <QRCode value={invoiceData.paymentRequestUri} size={200} />
                                    </Box>
                                </a>
                                <Text fontSize="xs" textAlign="center" mt={2}>
                                    Click en el QR (Click on the QR)
                                </Text>
                            </Box>
                        </Center>
                    )}

                    <Stack spacing={4}>
                        <Box>
                            <Text fontSize="sm">Crypto</Text>
                            <Input type="text" value={invoiceData.payCurrency} />
                        </Box>

                        <Box>
                            <Text fontSize="sm">Total EUROS</Text>
                            <Input type="text" value={Number(totalWithTip).toFixed(2) + " €"} />
                        </Box>

                        <Box>
                            <Text fontSize="sm">Comisión</Text>
                            <Input type="text" value={totalComision  + " " + invoiceData.payCurrency} />
                        </Box>

                        <Box>
                            <Text fontSize="sm">Total {invoiceData.payCurrency}</Text>
                            <InputGroup>
                                <Input
                                    type="text"
                                    value={invoiceData.payAmount + " " + invoiceData.payCurrency}
                                    onClick={onCopyAmount}
                                    cursor={"pointer"}
                                />
                                <InputRightElement children={<FaCopy />} onClick={onCopyAmount} cursor={"pointer"} />
                            </InputGroup>
                        </Box>

                        <Box>
                            <Text fontSize="sm">Dirección de pago (Payment address)</Text>
                            <InputGroup>
                                <Input type="text" value={invoiceData.paymentAddress} onClick={onCopyAddress} cursor={"pointer"} />
                                <InputRightElement children={<FaCopy />} onClick={onCopyAddress} cursor={"pointer"} />
                            </InputGroup>
                        </Box>

                        <Box>
                            <Text fontSize="sm">Cotización (Price by B2M)</Text>
                            <Input
                                type="text"
                                value={
                                    `1 ${invoiceData.payCurrency} = ` +
                                    (invoiceData.priceAmount / Number(invoiceData.payAmount)).toFixed(2) +
                                    " €"
                                }
                            />
                        </Box>
                    </Stack>
                </>
            )}

            {counter <= 0 && !paymentDetected && <Error message="El tiempo de pago ha expirado (Payment time has expired)" />}

            {paymentDetected && (
                <>
                    <Heading fontSize="lg" textAlign="center">
                        Pago completado
                    </Heading>
                    <Text fontSize="sm" textAlign="center">
                        Payment completed
                    </Text>
                    <Center>
                        <Image src="/images/check.png" alt="Check" boxSize="200px" mx="auto" my={4} />
                    </Center>
                    <Text fontSize="sm" textAlign="center">
                        Muchas gracias (Thank you)
                    </Text>
                </>
            )}
        </Stack>
    );
};

export default ProcessPayment;
