import React from 'react';
import {  ChakraProvider } from '@chakra-ui/react';
import { ColorModeSwitcher } from './components/ColorModeSwitcher/ColorModeSwitcher';

import Home from './pages/Home/Home';
import { theme } from './data/theme';

function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeSwitcher />
      <Home/>
    </ChakraProvider>
  );
}

export default App;
