// ----------------------------------------------------------------
// -----------------  Configuración de Chakra UI  -----------------
// ----------------------------------------------------------------

import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools';
import '@fontsource/varela-round/400.css'

const config = {
    initialColorMode: 'light',
    useSystemColorMode: true,
}

const fonts = { heading: `Varela Round`, body: `Varela Round` }
  
const styles = {
    global: props => ({
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'black')(props),
      },
    }),
}
  
const components = {
    Drawer: {
      // setup light/dark mode component defaults
      baseStyle: props => ({
        dialog: {
          bg: mode('white', 'dark')(props),
        },
      }),
    },
};
  
  export const theme = extendTheme({ config, components,  styles, fonts });