import axios from "axios";

const API_URL = process.env.API_URL || 'https://nassau-api.981block.com/';
// const API_URL = "http://localhost:3333";

const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
        "api-key": "E1UbDxj3m5PXc0dmS6erkR8cSGMQiBWYyBgXqh0quB",
    },
});

let creatingInvoice = false;

export const createInvoice = async (data) => {
    if (creatingInvoice) {
        console.log("Another request is already in progress.");
        return;
    }

    try {
        creatingInvoice = true;
        const response = await api.post("/invoice", data);
        return response.data;
    } catch (error) {
        console.log("🚀 ~ file: apiRequest.js:15 ~ createInvoice ~ error:", error);
    } finally {
        creatingInvoice = false;
    }
};

let isFetchingInvoice = false;

export const getInvoice = async (invoiceId) => {
    if (isFetchingInvoice) {
        console.log("Another request is already in progress.");
        return;
    }
    try {
        isFetchingInvoice = true;
        const response = await api.get(`/invoice/${invoiceId}`);
        return response.data;
    } catch (error) {
        console.log("🚀 ~ file: apiRequest.js:24 ~ getInvoice ~ error:", error);
    } finally {
        isFetchingInvoice = false;
    }
};

let isFetchingComision = false;

export const getComision = async () => {
    if (isFetchingComision) {
        console.log("Another request is already in progress.");
        return;
    }

    try {
        isFetchingComision = true;
        const response = await api.get("/comision");
        const comision = parseFloat(response.data.response.body.comision);
        return comision;
    } catch (error) {
        console.log("🚀 ~ file: apiRequest.js:24 ~ getComision ~ error:", error);
    } finally {
        isFetchingComision = false;
    }
};

let isFetchingCurrency = false;

export const getCurrencies = async () => {
    if (isFetchingCurrency) {
        console.log("Another request is already in progress.");
        return;
    }

    try {
        isFetchingCurrency = true;
        const response = await api.get("/currencies");
        return response.data.response.body;
    } catch (error) {
        console.error("🚀 ~ file: apiRequest.js:37 ~ getCurrencies ~ error:", error);
    } finally {
        isFetchingCurrency = false;
    }
};

export const getPing = async () => {
    try {
        const response = await api.get("/ping");
        return response.data;
    } catch (error) {
        console.error("🚀 ~ file: apiRequest.js:37 ~ getPing ~ error:", error);
    }
}