import { Box, Button, Heading, Input, InputGroup, InputRightAddon, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";

const TipSelector = ({ handleChangeTip, handleChangeValueTip  }) => {

    const borderColor = useColorModeValue("black", "white");

    return(
        <Box>
            <Heading fontSize="lg" textAlign="center" mb={2}>
                Propina (Tip)
            </Heading>
            <Box>
                <Text fontSize="sm" textAlign="center">
                    Por porcentaje / By percentage
                </Text>

                <Grid mt={4} templateColumns='repeat(3, 1fr)' columnGap={1} rowGap={2}>
                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeTip} value={0} border="2px" variant="outline" borderColor={borderColor}>
                            0%
                        </Button>
                    </GridItem>

                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeTip} value={10} border="2px" variant="outline" borderColor={borderColor}>
                            10%
                        </Button>
                    </GridItem>

                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeTip} value={20} border="2px" variant="outline" borderColor={borderColor}>
                            20%
                        </Button>
                    </GridItem>

                    <GridItem w="100%" colSpan={3}>
                        <InputGroup fontSize="xl" size="md" w="100%" border="2px" rounded="lg">
                            <Input p={4} fontWeight="bold" textAlign="center" placeholder="Porcentaje / Percentage" onChange={handleChangeTip} border="none" />
                            <InputRightAddon p={4} fontWeight="bold" fontSize="xl" minW="10" children="%" border="none" />
                        </InputGroup>
                    </GridItem>
                </Grid>

                <Text fontSize="sm" textAlign="center" my={4}>
                    Importe fijo / Fixed amount
                </Text>

                <Grid mt={4} templateColumns='repeat(3, 1fr)' columnGap={1} rowGap={2}>
                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeValueTip} value={0} border="2px" variant="outline" borderColor={borderColor}>
                            0 €
                        </Button>
                    </GridItem>

                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeValueTip} value={10} border="2px" variant="outline" borderColor={borderColor}>
                            10 €
                        </Button>
                    </GridItem>

                    <GridItem w="100%">
                        <Button w="100%" p={4} fontSize="md" fontWeight="bold" onClick={handleChangeValueTip} value={25} border="2px" variant="outline" borderColor={borderColor}>
                            25 €
                        </Button>
                    </GridItem>

                    <GridItem w="100%" colSpan={3}>
                        <InputGroup fontSize="xl" size="md" w="100%" border="2px" rounded="lg">
                            <Input p={4} fontWeight="bold" textAlign="center" placeholder="Importe / Amount" onChange={handleChangeValueTip} border="none"/>
                            <InputRightAddon p={4} fontWeight="bold" fontSize="xl" minW="12.5" children="€" border="none"/>
                        </InputGroup>
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    );
}

export default TipSelector;