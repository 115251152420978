import { Box, Center, Heading, Spinner, Text } from "@chakra-ui/react";

const Waiting = ({ title, message }) => {
    return (
        <Box border="1px" px={6} py={4} mt={-0.5} shadow="2xl">
            <Heading fontSize="lg" textAlign="center" mb={2}>
                {title}
            </Heading>
            <Text fontSize="sm" textAlign="center">
                {message}
            </Text>
            <Center>
                <Spinner size="xl" mt={4} color="blue.500" />
            </Center>
        </Box>
    );
};

export default Waiting;
