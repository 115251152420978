import { Box, Heading, useColorModeValue } from "@chakra-ui/react";

const Encabezado = ({ msg }) => {
    // Colores para el modo claro y oscuro
    const bgColor = useColorModeValue("black", "black");
    const textColor = useColorModeValue("white", "white");
    const borderColor = useColorModeValue("black", "white");

    return (
        <Box
            border="1px"
            p={2}
            roundedTop="3xl"
            bgColor={bgColor}
            color={textColor}
            borderColor={borderColor}
            textTransform="uppercase"
            shadow="lg">
            <Heading fontSize="lg" textAlign="center">
                {msg}
            </Heading>
        </Box>
    );
};

export default Encabezado;
