import { Box, Button, Center, Heading, Text } from "@chakra-ui/react";

const Error = ({ message = "Please, try again." }) => {
    const refreshPage = () => {
        window.location.reload();
    };

    return (
        <Box border="1px" m={4} p={4} rounded="3xl" bgColor="white" color="red" borderColor="blackAlpha.300">
            <Heading fontSize="4xl" textAlign="center" color="red">
                ERROR
            </Heading>

            <Text textAlign="center" fontWeight="bold" color="tomato">
                {message}
            </Text>

            <Center>
                <Button onClick={refreshPage} colorScheme="red" size="lg" m={4}>
                    Refresh
                </Button>
            </Center>
        </Box>
    );
};

export default Error;
