import { Box, Button, Collapse, Heading, Select, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import Encabezado from "../../components/Encabezado/Encabezado";

const SelectCurrency = ({
    invoiceTitle,
    prevStep,
    nextStep,
    currencies,
    selectedCurrency,
    setSelectedCurrency,
    selectedNetwork,
    setSelectedNetwork,
    email,
    setEmail,
}) => {
    // Colores para el modo claro y oscuro
    const totalBgColor = useColorModeValue("#F5F5F5", "#282828");

    const handleCurrency = (currencyId) => {
        const find = currencies.find((currency) => currency.currencyId === currencyId);
        setSelectedCurrency(find);
    };

    const handleNetwork = (network) => {
        const find = selectedCurrency.networks.find((net) => net.name === network);
        setSelectedNetwork(find);
    };

    return (
        <Box px={{ base: 6, md: 8 }} py={2}>
            <Encabezado msg={invoiceTitle} />

            <Box border="1px" px={6} py={4} mt={-0.5} shadow="2xl">
                <Heading fontSize="md" textAlign="center" mb={2}>
                    Criptomoneda (Crypto)
                </Heading>
                <Select
                    placeholder="Select the currency"
                    mb={4}
                    value={selectedCurrency?.currencyId}
                    onChange={(e) => handleCurrency(e.target.value)}>
                    {currencies &&
                        currencies.map((currency) => (
                            <option key={currency.currencyId} value={currency.currencyId}>
                                {currency.name} ({currency.currencyId})
                            </option>
                        ))}
                </Select>

                <Collapse in={selectedCurrency}>
                    <Heading fontSize="md" textAlign="center" mb={2}>
                        Red (Network)
                    </Heading>
                    <Select
                        placeholder="Select the network"
                        mb={4}
                        value={selectedNetwork?.name}
                        onChange={(e) => handleNetwork(e.target.value)}>
                        {selectedCurrency &&
                            selectedCurrency.networks.map((network) => (
                                <option key={network.name} value={network.name}>
                                    {network.name}
                                </option>
                            ))}
                    </Select>
                </Collapse>
            </Box>

            <Box border="1px" bgColor={totalBgColor} roundedBottom="3xl" shadow="xl">
                <SimpleGrid my={4} mx={6} columns={2}>
                    <Button
                        p={8}
                        fontSize=""
                        fontWeight="bold"
                        roundedLeft="full"
                        bgColor="#00AEE1"
                        color="white"
                        onClick={() => prevStep()}>
                        VOLVER <br />
                        PREV
                    </Button>
                    <Button
                        p={8}
                        fontSize="md"
                        fontWeight="bold"
                        roundedRight="full"
                        bgColor="#00AEE1"
                        color="white"
                        isDisabled={!selectedNetwork || !selectedCurrency}
                        onClick={() => nextStep()}>
                        SIGUIENTE <br />
                        NEXT
                    </Button>
                </SimpleGrid>
            </Box>
        </Box>
    );
};

export default SelectCurrency;
